<template>
  <div class="card mb-3">
    <div class="row g-0">
      <div class="col-md-9">
        <div class="card-body">
          <h5 class="card-title text-nowrap">Order is empty <i class="bi bi-magic align-top ms-2 mb-0"></i></h5>
          <p class="card-text mb-0">Ther's no order !</p>
        </div>
      </div>
      <div class="col-md-3 bg-label-primary d-flex justify-content-center align-items-center">
        <div class="">
          <i class="bi bi-cart-x-fill h1 text-primary"></i>
        </div>
        <!-- <img class="card-img card-img-right" src="../../assets/img/elements/17.jpg" alt="Card image" /> -->
      </div>
    </div>
  </div>
  <!-- <div class="card">
    <div class="card-body mb-0 shadow alert alert-primary">
      <h6 class="alert-heading mb-1">
        <i class="bi bi-magic align-top me-2"></i>
        <p class="mb-0 d-inline">Empty Order</p>
      </h6>
      <p>

      </p>
    </div>
  </div> -->
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
